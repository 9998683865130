$primaryColor: #224373;
$secondaryColor: #66c0ff;
$bgColor: #e5e5e5;
$whiteColor: #ffffff;
$grey: #9aaac1;
$liteBlue: #d8ecfb;
$disabled: #e3e3e3;
$liteGrey: #dbdbdb;
@mixin buttonStyle($backgroundColor) {
  width: 220px;
  height: 55px;
  background-color: $backgroundColor;
  color: $whiteColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 18px;
}
@mixin centerAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin inputFiled {
  // input {
  //   border: none;
  //   background-color: #ffffff;
  //   padding: 18px;
  //   border-radius: 5px;
  //   width: 85%;
  //   height: 20px;
  //   margin: 10px auto 15px 5px;
  //   outline: none;
  //   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  //   position: relative;
  //   color: $primaryColor !important;
  //   font-size: 16px;
  //   &:focus {
  //     border: 0.5px solid $secondaryColor;
  //     transition: all 0.3s ease;
  //   }
  //   &:disabled {
  //     background-color: $disabled;
  //     cursor: not-allowed;
  //   }
  //   &::placeholder {
  //     font-size: 0.9em;
  //     opacity: 0.7;
  //   }
  // }
}

;@import "sass-embedded-legacy-load-done:0";